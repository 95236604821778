/* You can add global styles to this file, and also import other style files */

@import "assets/app_styles.scss";
@import "assets/plugins/web-fonts/icons.css";
@import "assets/plugins/web-fonts/font-awesome/font-awesome.min.css";
@import "assets/plugins/web-fonts/plugin.css";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-snack-bar-container{
    margin: 8px auto !important;
    box-shadow: 0 1px 4px #0006;
    background: var(--primary);
    color: white;
    border-radius: 8px !important;

    background-image: url(./assets/img/trianglePattern.png);
    background-size: cover;
    
    .mat-simple-snackbar-action{
        color: white;
        background: #0002;
        border-radius: 5px;
    }
}

.mat-table, .mat-paginator{background-color: transparent;}

.swal2-container{

  padding: 0 0 0 80px !important;
  bottom: 0 !important;
  left: 0 !important;
  pointer-events: none;
  box-shadow: 0 2px 14px #0006;

  .swal2-popup{
    pointer-events: all;
    
    &:not(.swal2-toast){
      background-image: url(./assets/img/modal-texture.png) !important;
      background-size: cover;
      border-radius: 11px !important;
      width: 420px !important;

      .swal2-content{
        font-size: 1rem !important;
      }
    }

    &.swal2-toast{
    margin: 8px auto !important;
    box-shadow: 0 1px 4px #0006;
    color: white;
    border-radius: 8px !important;
    padding: 4px 10px !important;
  
    background-image: url(./assets/img/trianglePattern.png) !important;
    background-size: cover !important;
    // font-size: inherit !important;
  }
  }
  .swal2-actions{
    justify-content: space-between;
    padding: 0 !important;
    flex-wrap: nowrap !important;

    .swal2-styled{border-radius: 11px !important; min-width: 33%; margin: 5px auto;}
    .swal2-confirm.swal2-styled{
      background-color: var(--success) !important;
      order: 2;
    }
    .swal2-cancel.swal2-styled{
      background-color: var(--danger) !important;
      order: 1;
    }
  }
}

.ngx-slider{
    .ngx-slider-bar-wrapper {
        padding-top: 12px !important;
    }

    .ngx-slider-bubble{font-size: 14px !important;}

    .ngx-slider-pointer{
        width: 24px !important;
        height: 24px !important;
        background-color: var(--primary) !important;

        &::after{top: 8px !important;left: 8px !important;}

        &.ngx-slider-active::after{background-color: white !important;}
    }
}

mention-list{

  chat &.dropup{
    top: -10px !important;
    left: 10px !important;
    margin-bottom: 0 !important;
  }

  ul{
    color: black;
    li{
      .dropdown-item{
        padding: 10px !important;
        font-weight: 500;
        cursor: pointer;

        img{background-color: white; border-radius: 20%; margin-right: 0 !important;}
      }

      &.active, &:hover{
        color: white;

        .dropdown-item{font-weight: bold;}
      }

      &.active, .dropdown-item:hover{background-color: var(--primary) !important;}
    }
  }
}


.__mention{
  color: var(--det-color);
  font-weight: bold;
  position: relative;
  padding: 4px 8px;
  z-index: 1;

  &::before{
    content: "@";
    display: inline-block;
    position: relative;
    top: -2px;
    font-size: .9em;
    opacity: .7;
    margin-right: 2px;
  }

  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;bottom: 0;
    left: 0;right: 0;
    border-radius: 5px;
    background-color: var(--det-color);
    opacity: 0.1;
    z-index: -1;
  }

  .bubble &{border-radius: 5px;}

  .bubble.self &{--det-color: white;}

}


request-detail .input-group.date ngx-daterangepicker-material{
    position: relative;
    right: -200px;
}

chat-card{
  --det-color : var(--primary);
}
carousel{

  img{object-fit: contain !important; pointer-events: none !important;}
}

.modal-content mk-loader{
  z-index: 10;
  position: absolute;
  --loaderPos: absolute;
  border-radius: 8px;
}