/* Importing node modules SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import "~simple-datatables/dist/style.css";
@import "~leaflet/dist/leaflet.css";
@import "~chartist/dist/scss/chartist.scss";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-toastr/toastr.css';
@import '~angular-archwizard/archwizard.css';
@import '~animate.css/animate.min.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~angular-calendar/scss/angular-calendar.scss";
@import "~flatpickr/dist/flatpickr.css";
@import '~highlight.js/scss/solarized-dark.scss';

//custom scss
@import "scss/style.scss";
// @import "css/skins.scss";
// @import "css/dark-style.scss";
// @import "css/colors/default.scss";
@import "assets/css/icon-list.css";
// @import "css/colors/color.scss";

@import "css/sidemenu/sidemenu.scss";

@import "../app/components/mk-table/mk-table.scss";


.st-default,.st-new, .st-created{background-color: #666};
.st-analisis{background-color: #427dec};
.st-auth_pen_adm{background-color: #417dec};
.st-auth_pen_cli{background-color: #417dec};
.st-autorizado{background-color: #2d3dce};
.st-desarrollo{background-color: #e67c35};
.st-trabajando{background-color: #edb821};
.st-lib_pen_adm{background-color: #e77d35};
.st-lib_pen_cli{background-color: #e77d35};
.st-accepted{background-color: #35c063};
.st-liberado{background-color: #38c063}; //22a84f
.st-cerrado{background-color: #35363f};
.st-finished{background-color: #35363f};
.st-rechazado, .st-rejected{background-color: #ec5353};
.st-cancelled,.st-closed, .st-cancelado{background-color: #ec5353};
.st-lib_rej_cli{background-color: #ec5353};
.st-expire{background-color: #ca417a};
.st-rejected_provider{background-color: #e67c35};

.st-active, .st-started{background-color: #35c063};
.st-warning, .st-request{background-color: #ecb709};

.st-inactive{background-color: #acacac};
.st-default,.st-new,.st-created{background-color: #666 };
.st-analisis{background-color: #427dec };
.st-auth_pen_adm{background-color: #ecb709 };
.st-pending{background-color: #ecb709 };
.st-auth_pen_cli{background-color: #417dec };
.st-request{background-color: #417dec };
.st-autorizado{background-color: #2d3dce };
.st-accepted{background-color: #2d3dce };
.st-desarrollo{background-color: #e67c35 };
.st-trabajando{background-color: #edb821 };
.st-started{background-color: #35c063 };
.st-lib_pen_adm{background-color: #e77d35 };
.st-lib_pen_cli{background-color: #e77d35 };

.st-closed{background-color: #35363f };
.st-liberado{background-color: #35363f} ; 
.st-cerrado{background-color: #35363f };
.st-finished{background-color: #35363f };
.st-rejected{background-color: #35363f };
.st-rejected_provider{background-color: #35363f };

.st-rechazado{background-color: #ec5353 };
.st-cancelled{background-color: #ec5353 };
.st-expire{background-color: #f06e3b };
.st-lib_rej_cli{background-color: #ec5353 };

.badge{
    &.badge-st-default,&.badge-st-new, &.badge-st-created{background-color: #666666; color: #fff};
    &.badge-st-analisis{background-color: #427dec; color: #fff};
    &.badge-st-auth_pen_adm{background-color: #417dec; color: #fff};
    &.badge-st-auth_pen_cli{background-color: #417dec; color: #fff};
    &.badge-st-autorizado{background-color: #2d3dce; color: #fff};
    &.badge-st-desarrollo{background-color: #e67c35; color: #fff};
    &.badge-st-trabajando{background-color: #edb821; color: #fff};
    &.badge-st-lib_pen_adm{background-color: #e77d35; color: #fff};
    &.badge-st-lib_pen_cli{background-color: #e77d35; color: #fff};
    &.badge-st-liberado{background-color: #38c063; color: #fff};
    &.badge-st-cerrado{background-color: #35363f; color: #fff};
    &.badge-st-na{background-color: #666666; color: #fff};
    &.badge-st-finished{background-color: #35363f; color: #fff};
    &.badge-st-rechazado, &.badge-st-rejected{background-color: #ec5353; color: #fff};
    &.badge-st-lib_rej_cli{background-color: #ec5353; color: #fff};
    &.badge-st-expire{background-color: #ca417a; color: #fff};
    &.badge-st-revision{background-color: #edb821; color: #fff};
    &.badge-st-mxn{background-color: #269ab4; color: #fff};
    &.badge-st-usd{background-color: #759117; color: #fff};



    &.badge-st-rejected_provider{background-color: #e67c35; color: #fff};
    &.badge-st-success{background-color: #35c063; color: #fff};
    &.badge-st-cancelled, &.badge-st-cancelado{background-color: #ec5353; color: #fff};
    &.badge-st-pendiente{background-color: #e67c35; color: #fff};

    &.badge-st-active,&.badge-st-started, &.badge-st-activa, &.badge-st-activo{background-color: #35c063; color: #fff};
    &.badge-st-danger,&.badge-st-closed, &.badge-st-inactivo, &.badge-st-inactive{background-color: #ec5353; color: #fff};

    &.badge-st-warning,&.badge-st-request{background-color: #ecb709; color: #fff};

    
    &.badge-st-accepted {background-color: #2d3dce; color: #fff};
    &.badge-st-closed {background-color: #35363f; color: #fff};

}

.pac-container {
    z-index: 2000;
    display: inline-block;
}
.md-drppicker{
    display: flex;
    top: 55px !important;
    
    &.double{left: unset !important; right: 0 !important;}
}

.btn i.typcn.typcn-eye {
    line-height: 12px;
    font-size: 1.1em;
}

app-sidemenu{
    .ps__rail-x, .ps__rail-y{display: none !important;}
}


.main-content{
    --mkt-scroll: #17203d54;
    
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 90vh;
        background-image: url(../assets/img/pattern.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        right: 0;bottom: 0;
        opacity: 0.2;
        pointer-events: none;
        mix-blend-mode: darken;
    }
}

tr.mat-header-row{height: fit-content !important;}
th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{padding-left: 6px !important;}
th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{padding-right: 6px !important;}
.mat-sort-header-arrow{top: -2px; color: var(--primary);}
.mat-row{height: fit-content !important;}

.mat-paginator{border-top: 1px solid #0000001a;}
.mat-paginator-range-actions button{
    border-radius: 8px;
    border: 1px solid #0002;

    &:not(:last-child){margin-right: 8px;}

    .mat-button-wrapper{
        position: relative;top: -2px;
    }
    

    &:not(.mat-button-disabled){color: var(--primary);}
}

mkt-dropdown{
    .md-drppicker{
        top: 0 !important;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        margin: 0 !important;

        .calendar-table{
            background-color: transparent;
        }
    }
}

aw-wizard-navigation-bar li.editing .step-indicator,
aw-wizard-navigation-bar li.completed .step-indicator,
aw-wizard-navigation-bar li.current .step-indicator{background-color: var(--info) !important;}

aw-wizard-navigation-bar li.done{
    &:not(.current){
        .step-indicator{background-color: #E6E6E6 !important;}
    }
}


.__filters{
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 86px;
    border-radius: 5px;
    right: 23px;
    z-index: 5;

    box-shadow: 0 2px 10px 6px rgb(27 27 54 / 22%);
  
    .input-group{
      width: 240px;
      margin-bottom: 0 !important;
  
      &.search{width: 250px;}
      &.date{width: 230px;}
  
      &:not(:last-child){input{border-radius: 0;}}
      &:not(:first-child){.input-group-text{border-radius: 0; border-left: 0;}}
    }

    ng-select{
        height: 38px !important;
    }
  }

  .box-drag:nth-child(even) {
      .card, .mkt_row:nth-child(even) ._controls:not(.__del), mkt-input .bg-white{
          //old color fdfef2
          background-color: #fdfef2;
        }
        .card .mkt_row:nth-child(odd) ._controls{background-color: #fdfef2cc;}

    }

    .main-sidebar-body li.nav-item.active li.nav-sub-item{pointer-events: all !important;}

    .custom-card.__border-top{border-top: 2px solid var(--primary);}

    
.__texture{
    // background-image: url(./img/trianglePattern.png);
    // background-size: cover;
}

// NUMBER INPUT ARROWS

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}


.ng-select .ng-select-container{background: unset; &.ng-select-disabled{background-color: #6661;}}
ng-select{
    height: fit-content !important;

    .ng-dropdown-panel{min-width: fit-content;}
}
textarea{resize: none;}

// apx-chart{zoom: calc(2 - var(--zoomValue));}